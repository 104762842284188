<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title>
          Relevé
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">

          <v-simple-table class="table-border rounded">
            <template v-slot:default>
              <tbody>

              <tr>
                <td>Campagne</td>
                <td>
                  {{ statement.statement_campaign ? statement.statement_campaign.name : "-" }}
                </td>
              </tr>

              <tr class="grey lighten-3">
                <td>Superviseur</td>
                <td>
                  {{ statement.supervisor ? statement.supervisor.name : "-" }}
                </td>
              </tr>

              <tr>
                <td>Nom du grossiste</td>
                <td>
                  {{ statement.wholesaler ? statement.wholesaler.name : "-" }}
                </td>
              </tr>

              <tr class="grey lighten-3">
                <td>Code du grossiste</td>
                <td>
                  {{ statement.wholesaler ? statement.wholesaler.code : "-" }}
                </td>
              </tr>

              <tr>
                <td>Image</td>
                <td>
                  <div class="pa-2">
                    <v-img v-if="statement.image"
                           :src="FILE_URL + statement.image"
                           class="rounded zoom-pointer"
                           height="45"
                           width="45" @click="[image = [{src: FILE_URL + statement.image}],visible = true]"/>

                    <v-img v-else :src="require('@/assets/no-image.png')"
                           class="rounded img-border"
                           height="45"
                           width="45"/>
                  </div>
                </td>
              </tr>

              <tr class="grey lighten-3">
                <td>Type de récompense</td>
                <td class="font-weight-medium blue--text">
                      <span v-if="statement.rewarded_type">
                         {{ statement.rewarded_type === "digital" ? "Digital" : "Physique" }}
                      </span>
                  <span v-else>-</span>
                </td>
              </tr>

              <tr>
                <td>Map</td>
                <td>
                  <div class="d-block text-no-wrap">
                    <a :href="'https://www.google.com/maps/search/?api=1&query=' + statement.latitude + ',' + statement.longitude"
                       class="blue--text text-decoration-none"
                       target="_blank">
                      <v-icon color="blue" dense>mdi-map-marker</v-icon>
                      {{ statement.latitude }} - {{ statement.longitude }}
                    </a>
                  </div>
                </td>
              </tr>

              <tr class="grey lighten-3">
                <td>Distance</td>
                <td class="font-weight-medium">
                  {{ statement.distance }} m
                </td>
              </tr>

              <tr>
                <td>Date</td>
                <td>
                  {{ statement.created_at }}
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>


      <vue-easy-lightbox :imgs="image"
                         :index="index"
                         :visible="visible"
                         escDisabled
                         moveDisabled
                         @hide="visible = false"></vue-easy-lightbox>

    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      statement: {},

      //Show image
      image: '',
      visible: false,
      index: 0,
      FILE_URL: process.env.VUE_APP_FILE_URL
    }
  },
  methods: {
    open(statement) {
      this.dialog = true
      this.statement = statement
    }
  }
}
</script>

<style scoped>

</style>