<template>
  <div>
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-title>
          Nouveau code QR
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">

          <v-text-field v-model="form.code"
                        :error-messages="errors.code"
                        label="Code QR *"></v-text-field>

          <v-text-field v-model="form.winning_points"
                        :error-messages="errors.winning_points"
                        label="Points gagnants *"></v-text-field>

        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="loading" class="rounded-lg" color="primary" depressed
                 @click="save">
            <v-icon left>mdi-content-save-outline</v-icon>
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      form: {},
      errors: {},
    }
  },
  methods: {
    open() {
      this.dialog = true
      this.form = {}
      this.errors = {}
    },
    save() {
      this.loading = true
      this.errors = {}
      HTTP.post('/qr-codes/store', this.form).then(() => {
        this.loading = false
        this.$successMessage = 'Ce code QR a été enregistré avec succès'
        this.$emit('get-data')
        this.form = {}
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.loading = false
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>

</style>