<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <v-row>
        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else>
                {{ stat.total }}
              </div>
              <v-spacer></v-spacer>
              <v-avatar color="blue" size="40">
                <v-icon dark>mdi-qrcode-scan</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text class="mt-3">
              <div class="d-flex justify-space-between align-center">
                <div>
                  Total codes QR
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else class="text-no-wrap">
                {{ stat.total_scanned }}
                <small v-if="!loading" class="success--text font-weight-light fs-14">
                  /
                  <!--                  {{ stat.winning_points | toCurrency }}-->
                  {{ stat.winning_points }}
                </small>
              </div>
              <v-spacer></v-spacer>
              <v-avatar color="success" size="40">
                <v-icon dark>mdi-check</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text class="mt-3">

              <div class="d-flex justify-space-between align-center">
                <div>
                  Total codes QR scannés
                </div>
                <div v-if="!loading" class="blue--text">
                  {{ stat.percentage }}%
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.today_scanned }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="indigo" size="40">
                <v-icon dark>mdi-calendar-today-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text class="mt-3">
              Codes QR scannés aujourd'hui
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3">
          <v-card class="rounded-lg shadow">
            <v-card-title class="pb-1">
              <v-skeleton-loader v-if="loading" type="text" width="100"></v-skeleton-loader>
              <div v-else> {{ stat.this_month_scanned }}</div>
              <v-spacer></v-spacer>
              <v-avatar color="primary" size="40">
                <v-icon dark>mdi-calendar-month-outline</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-text class="mt-3">
              Codes QR scannés ce mois
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card :disabled="overlay" :loading="overlay" class="rounded-lg shadow">
            <v-card-title>

              <div>
                <v-text-field v-model="keyword" append-icon="mdi-magnify" class="w-300" clearable
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>

              <v-spacer/>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-2" icon v-bind="attrs" @click="refresh" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-1"
                         icon
                         v-bind="attrs"
                         @click="ExportExcel"
                         v-on="on">
                    <v-icon>mdi-download-outline</v-icon>
                  </v-btn>
                </template>
                <span>Expoter</span>
              </v-tooltip>

              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-1"
                         color="blue"
                         dark depressed fab small
                         v-bind="attrs"
                         @click="[
                                  importDialog = true,
                                  excel_file = '',
                                  winning_points = 0,
                                  $refs.excel_file ?  $refs.excel_file.reset() : false,
                                  info = '',
                                  errors = [],
                                  ExcelErrors = [],
                                  ]"
                         v-on="on">
                    <v-icon>mdi-import</v-icon>
                  </v-btn>
                </template>
                <span>Importer</span>
              </v-tooltip>


              <v-btn class="ml-1" color="blue" dark depressed fab small
                     @click="addItem"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>

            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-card-text v-if="!loading && qrCodes.length === 0" class="text-center">
              <NoResult/>
            </v-card-text>
            <div v-if="!loading && qrCodes.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Code</th>
                    <th>Points</th>
                    <th>Scanné</th>
                    <th>Scanné le</th>
                    <th>Relevé</th>
                    <th>Créé le</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in qrCodes" :key="item.id">

                    <td class="font-weight-medium blue--text">
                      {{ item.code }}
                    </td>

                    <td>
                      <v-chip color="success" dark small>
                        {{ item.winning_points | toCurrency }}
                      </v-chip>
                    </td>

                    <td>
                      <v-icon v-if="item.is_scanned" color="success">mdi-check-circle</v-icon>
                      <v-icon v-else color="red">mdi-close-circle</v-icon>
                    </td>

                    <td>
                      {{ item.scanned_at }}
                    </td>

                    <td>
                      <v-btn :dark="!!(item.is_scanned)"
                             :disabled="!(item.is_scanned)"
                             @click="$refs.statementDialog.open(item.statement)"
                             color="blue"
                             depressed
                             small
                             text>
                        <v-icon left>mdi-eye-plus</v-icon>
                        Voir
                      </v-btn>
                    </td>

                    <td>
                      {{ item.created_at }}
                    </td>

                    <td>
                      <v-tooltip color="primary" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" icon v-bind="attrs" @click="deleteItem(item.id)" v-on="on">
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Supprimer</span>
                      </v-tooltip>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-divider v-if="total > 10"/>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total>10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              circle
                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>

            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="importDialog" width="400">
        <v-card :loading="importBtnLoading">
          <v-card-title>
            Importer
            <v-spacer/>
            <v-icon @click="importDialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-8">
            <v-alert v-if="info" color="warning" text>
              <p>{{ info }}</p>
              <div v-for="(errors,i) in ExcelErrors" :key="i">
                <ul>
                  <li v-for="(err,index) in  errors" :key="index">
                    <strong>Ligne : {{ err.row }}</strong> / {{ err.message }}
                  </li>
                </ul>
              </div>
            </v-alert>

            <v-text-field v-model="winning_points"
                          :error-messages="errors.winning_points"
                          label="Points gagnants pour chaque code QR *"></v-text-field>

            <v-file-input ref="excel_file"
                          :error-messages="errors.excel_file"
                          accept=".xlsx, .xls"
                          hide-details="auto"
                          label="Fichier excel *"
                          show-size
                          small-chips
                          @change="uploadFile"
                          @click="errors && errors.excel_file ? errors.excel_file = '' : false"/>

          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn :loading="importBtnLoading" class="rounded-lg" color="primary" depressed
                   @click="importDatabase">
              <v-icon left>mdi-cloud-download-outline</v-icon>
              Importer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <QrCodeForm ref="qrCodeForm" @get-data="getQrCodes"/>
      <StatementDialog ref="statementDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import QrCodeForm from "./QrCodeForm.vue";
import StatementDialog from "./StatementDialog.vue";
import NoResult from "../../../components/NoResult";

export default {
  components: {NoResult, QrCodeForm, StatementDialog},
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,

      overlay: false,
      loading: false,
      importDialog: false,

      qrCodeForm: false,
      wholesalerDetails: false,

      id: '',
      qrCodes: [],
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },
      keyword: '',

      importBtnLoading: false,
      winning_points: 0,
      excel_file: '',
      errors: [],
      ExcelErrors: [],
      info: '',

      stat: {}

    }
  },
  methods: {
    uploadFile(file) {
      this.excel_file = file
    },
    deleteItem(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    importDatabase() {
      this.info = ''
      this.ExcelErrors = []
      this.importBtnLoading = true
      this.$Progress.start()
      let formData = new FormData()
      formData.append('excel_file', this.excel_file)
      formData.append('winning_points', this.winning_points)
      HTTP.post('/qr-codes/import', formData).then(() => {
        this.importBtnLoading = false
        this.$Progress.finish()
        this.$successMessage = 'Ces codes ont été importés avec succès'
        this.importDialog = false
        this.excel_file = ''
        this.getQrCodes()
      }).catch(err => {
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.importBtnLoading = false
        console.log(err)
      })
    },
    addItem() {
      this.$refs.qrCodeForm.open()
    },
    getQrCodes() {
      this.loading = true
      this.drawer = false
      this.$Progress.start()

      HTTP.get('/qr-codes?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          per_page: this.per_page,
        }
      }).then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.qrCodes = res.data.data.data

        this.stat = res.data.stat

        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    onPageChange() {
      this.getQrCodes();
    },

    ExportExcel() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/qr-codes?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
    refresh() {
      this.getQrCodes()
    }
  },
  created() {
    this.getQrCodes()
  },
  watch: {
    keyword() {
      this.getQrCodes();
    },
    per_page() {
      this.pagination.current = 1
      this.getQrCodes();
    },
    '$is_confirm': function (el) {
      if (el) {
        this.$is_confirm = false
        this.overlay = true
        this.$Progress.start()
        HTTP.delete('/qr-codes/delete/' + this.id).then(() => {
          let i = this.qrCodes.findIndex(item => item.id === this.id)
          this.$delete(this.qrCodes, i)
          this.$successMessage = 'Cet code qr a été supprimé avec succès'
          this.overlay = false
          this.$Progress.finish()
        }).catch(err => {
          this.overlay = false
          this.$Progress.fail()
          console.log(err)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>